<template>
  <aside
    ref="MainContent"
    class="fixed top-0 bottom-0 z-30 w-full overflow-x-hidden overflow-y-auto transform left-full"
  >
    <div
      data-dialog-box
      class="relative z-10 flex flex-col w-auto h-full ml-10 text-white bg-black"
    >
      <ol class="relative z-0 w-full px-10 pt-10">
        <li
          class="w-full border-b border-zinc-800"
          v-for="(item, item_index) in menu_list"
          :key="`item_${item_index}`"
        >
          <router-link
            v-if="item.type != 'list'"
            @click.native="$emit('menu-action')"
            class="block py-4 text-xl font-light"
            :to="item.link"
          >
            <span class="font-bold">{{ item.title }}</span>
            <span
              class="block text-sm font-bold text-left uppercase text-primary"
              >{{ item.sub_title }}</span
            >
          </router-link>
          <button
            v-else
            @click="sub_menu_status = true"
            class="block py-4 text-xl font-light"
          >
            <span class="font-bold">{{ item.title }}</span>
            <span
              class="block text-sm font-bold text-left uppercase text-primary"
              >{{ item.sub_title }}</span
            >
          </button>
        </li>
        <li class="w-full border-b border-zinc-800">
          <button
            @click="$emit('open-language-dialog'), $emit('menu-action')"
            class="relative block w-full py-4 text-xl font-light text-left"
          >
            <span class="font-bold"> 選擇語言</span>
            <i
              class="absolute z-10 text-xs text-white transform scale-90 -translate-y-1/2 pointer-events-none top-1/2 right-2 icon-chevron-down"
            ></i>
          </button>
        </li>
      </ol>

      <div
        :class="sub_menu_status ? '-translate-x-full' : ''"
        class="absolute top-0 z-10 w-full h-full px-10 pt-10 overflow-y-auto transition-all duration-300 transform bg-black left-full"
      >
        <button
          @click="sub_menu_status = false"
          class="flex items-center w-full mb-5 font-bold text-white"
        >
          <i class="mr-5 icon-chevron-left"></i>
          返回
        </button>
        <ol class="relative z-0 w-full">
          <li
            class="w-full border-b border-zinc-800"
            v-for="(item, item_index) in menu_category_data"
            :key="`product_category_item_${item_index}`"
          >
            <router-link
              @click.native="$emit('menu-action')"
              class="block py-4 text-lg font-light"
              :to="`/scooters/${item.MenuCategoryID}`"
            >
              <span class="font-bold">{{ item.Title }}</span>
            </router-link>
          </li>
        </ol>
      </div>
    </div>

    <div
      data-dialog-background
      @click="$emit('menu-action')"
      class="absolute top-0 bottom-0 left-0 right-0 z-0 bg-black bg-opacity-10 backdrop-blur-sm"
    ></div>
  </aside>
</template>

<script>
import { getLocalStorage } from "@/common/cookie";
import { MainMenu } from "@/gsap/MainMenu.js";
export default {
  name: "MainMenu",
  props: {
    menu_status: {
      require: true,
      type: Boolean,
    },
  },
  data() {
    return {
      menu_list: [
        {
          title: "關於我們",
          sub_title: "about",
          link: "/about",
          type: "item",
        },
        {
          title: "商品列表",
          sub_title: "PRODUCTS",
          link: "/scooters/all",
          type: "list",
        },
        {
          title: "影音專區",
          sub_title: "VIDEOS",
          link: "/video/list",
          type: "item",
        },
        {
          title: "最新消息",
          sub_title: "NEWS",
          link: "/news",
          type: "item",
        },
        {
          title: "客服中心",
          sub_title: "SUPPORT",
          link: "/support",
          type: "item",
        },
        {
          title: "經銷據點",
          sub_title: "DEALERS",
          link: "/dealers",
          type: "item",
        },
      ],
      menu_gsap: null,
      sub_menu_status: false,
    };
  },
  methods: {
    CheckLogin() {
      return getLocalStorage("account_token") != null;
    },
  },
  watch: {
    menu_status() {
      if (this.menu_status) {
        this.menu_gsap.open();
      } else {
        this.menu_gsap.close();
        this.sub_menu_status = false;
      }
    },
  },
  computed: {
    menu_category_data() {
      return this.$store.state.menu_category_data;
    },
  },
  mounted() {
    this.menu_gsap = new MainMenu(this.$refs.MainContent);
    this.menu_gsap.reset();
  },
};
</script>
